<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75056 6.25L13.1253 1.875M13.1253 1.875H9.37552M13.1253 1.875V5.625M6.25071 8.75L1.87598 13.125M1.87598 13.125H5.62575M1.87598 13.125L1.87598 9.375"
      stroke="white"
      stroke-opacity="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.74929 8.75L13.124 13.125M13.124 13.125V9.375M13.124 13.125H9.37425M6.24944 6.25L1.87471 1.875M1.87471 1.875V5.625M1.87471 1.875L5.62448 1.875"
      stroke="white"
      stroke-opacity="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
